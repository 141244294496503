import React from "react"
import { connect } from "react-redux"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import { lingua_it } from "../i18n/it-IT"
import { lingua_en } from "../i18n/en-GB"
import { lingua_es } from "../i18n/es-ES"
import { lingua_ca } from "../i18n/es-CA"

import Seo from "../components/seo"

const OurFabrics = props => {
  const { data, lang } = props

  let lingua = {}

  switch (lang) {
    case "it-IT":
      lingua = lingua_it
      break
    case "es-CA":
      lingua = lingua_ca
      break
    case "es-ES":
      lingua = lingua_es
      break
    case "en-GB":
      lingua = lingua_en
      break
    default:
      lingua = lingua_it
  }

  return (
    <div>
      <Seo
        title={lingua.sito.titolo}
        keywords={[
          `3D Shirt`,
          `configuratore 3d per camicie`,
          `camicie su misura`,
        ]}
        description={lingua.sito.sottotitolo}
      />
      <section className="section" style={{ background: "#f0f5f6" }}>
        <div className="container">
          <h1 className="title is-size-3-mobile is-2 is-spaced">
            {lingua.footer.ourfabrics}
          </h1>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content is-medium">
                <p className="has-text-weight-bold">
                  {lingua.sito.nostritessuti.sotto1}
                </p>
                <p>{lingua.sito.nostritessuti.p1}</p>

                <div className="columns">
                  <div className="column">
                    <Img fluid={data.fabric1.childImageSharp.fluid} />
                  </div>
                  <div className="column">
                    <Img fluid={data.fabric2.childImageSharp.fluid} />
                  </div>
                </div>
                <p className="title is-5">CANCLINI</p>
                <p>{lingua.sito.nostritessuti.p2}</p>
                <p className="title is-5">ALBINI</p>
                <p>{lingua.sito.nostritessuti.p3}</p>
                <p className="title is-5">ALBIATE</p>
                <p>{lingua.sito.nostritessuti.p4}</p>
                <p className="title is-5">THOMAS MASON</p>
                <p>{lingua.sito.nostritessuti.p5}</p>
                <p className="title is-5">TEXTA</p>
                <p>{lingua.sito.nostritessuti.p6}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  }
}

export default connect(mapStateToProps)(OurFabrics)

export const query = graphql`
  query {
    fabric1: file(relativePath: { regex: "/sito/fabric1.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fabric2: file(relativePath: { regex: "/sito/fabric2.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
